// index.tsx
import React from 'react';
import ReactDOM from 'react-dom/client';
import { ChakraProvider } from '@chakra-ui/react';
import App from './App';
import theme from './theme'; // Import your custom theme
import reportWebVitals from './reportWebVitals';
import './index.css';
import { DAppProvider, Config, Mainnet } from '@usedapp/core';

const config: Config = {
  readOnlyChainId: Mainnet.chainId,
  readOnlyUrls: {
    [Mainnet.chainId]: 'https://mainnet.infura.io/v3/92ca5fdb90504b409b8ac69b17e16a99',
  },
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <DAppProvider config={config}>
        <App />
      </DAppProvider>
    </ChakraProvider>
  </React.StrictMode>
);

reportWebVitals();
