import React from 'react';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Box,
  Flex,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import NavLink from './NavLink';
import ConnectWalletButton from './ConnectWalletButton';

interface MobileMenuProps {
  links: Array<{
    href: string;
    iconSrc: string;
    altText: string;
    label: string;
  }>;
}

const MobileMenu: React.FC<MobileMenuProps> = ({ links }) => {
  return (
    <Flex justifyContent="flex-end" alignItems="center" width="100%">
      <Box mr={4}>
        <ConnectWalletButton />
      </Box>
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<HamburgerIcon />}
          variant="outline"
          color="white"
          _hover={{ bg: 'transparent' }}
          _active={{ bg: 'transparent' }}
          _focus={{ bg: 'transparent', boxShadow: 'none' }}
        />
        <MenuList
          bg="rgb(31,110,186)"
          borderColor="rgb(31,110,186)"
          boxShadow="0px 5px 15px rgba(0, 0, 0, 0.3)"
        >
          {links.map((link, index) => (
            <MenuItem 
              key={index} 
              bg="rgb(31,110,186)"
              _hover={{ bg: 'rgb(41,120,196)' }}
              _focus={{ bg: 'rgb(41,120,196)' }}
            >
              <Box as="span" display="flex" alignItems="center" width="100%">
                <NavLink
                  href={link.href}
                  iconSrc={link.iconSrc}
                  altText={link.altText}
                  label={link.label}
                />
              </Box>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Flex>
  );
};

export default MobileMenu;