import React from "react";
import { Box, Text, Link, Flex, VStack, HStack, Image, Heading, Stack } from "@chakra-ui/react";

const Footer: React.FC = () => {
  return (
    <Box
      as="footer"
      bgGradient="linear(#185faa, #13549c, #1960ab)"
      color="white"
      boxShadow="0 -4px 5px rgba(0, 23, 57, .4)"
      fontSize="0.85rem"
      py={4}
      px={{ base: 4, md: 6, lg: 10 }}
    >
      <Stack
        direction={{ base: "column", md: "row" }}
        spacing={{ base: 8, md: 0 }}
        justifyContent="space-between"
        alignItems="center"
        maxW="1200px"
        mx="auto"
        width="100%"
      >
        {/* Logo */}
        <Box>
          <Heading as="h1" size="md" color="white" mt={3}>
            <img src="/assets/logo.png" alt="Club PenguiNFT" width="150"/>
          </Heading>
        </Box>

        {/* Links and Text */}
        <VStack spacing={4} textAlign="center" maxW={{ base: "100%", md: "600px" }}>
          <Stack direction={{ base: "column", sm: "row" }} spacing={{ base: 2, sm: 6 }} wrap="wrap" justify="center">
            <Link href="#" color="white">Terms of Service</Link>
            <Link href="#" color="white">Privacy Policy</Link>
            <Link href="#" color="white">Wiki</Link>
            <Link href="#" color="white">Support</Link>
          </Stack>

          <Text fontSize="0.75rem" px={2}>
            Club PenguiNFT is an independent fanmade recreation of
            Disney's Club Penguin. We are not affiliated with Club Penguin,
            Disney, and/or any of its services in any way.
          </Text>
        </VStack>

        {/* Social Media Icons */}
        <HStack spacing={6} justifyContent="center" mt={{ base: 4, md: 0 }}>
          <Link href="#" color="white" isExternal>
            <Image src="/assets/twitter-icon.svg" alt="Twitter" boxSize="30px" filter="brightness(0) invert(1)" />
          </Link>
          <Link href="#" color="white" isExternal>
            <Image src="/assets/discord-icon.svg" alt="Discord" boxSize="30px" filter="brightness(0) invert(1)" />
          </Link>
          <Link href="#" color="white" isExternal>
            <Image src="/assets/youtube-icon.svg" alt="YouTube" boxSize="30px" filter="brightness(0) invert(1)" />
          </Link>
        </HStack>
      </Stack>

      {/* Copyright text */}
      <Text textAlign="center" mt={6}>
        © ClubPenguiNFT 2024
      </Text>
    </Box>
  );
};

export default Footer;
