import React, {useState} from "react";
import { Flex, Box } from "@chakra-ui/react";
import LeftPanel from "./LeftPanel";
import RightPanel from "./RightPanel";

const CombinedPanel: React.FC = () => {
    const [referralCode, setReferralCode] = useState("");
    const referralInputConfig = {
      label: "Referral Code (Optional)",
      placeholder: "REFERRAL CODE",
      value: referralCode,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
        setReferralCode(e.target.value),
      validationStatus: 'neutral' as 'neutral',
    };
  return (
<Flex
      align="center"
      justify="center"
      width="100%"
      height="100vh"  // This will make sure the combined panel takes the full height of the viewport
      padding="20px" // Optional padding to give some space around the panels
      bg="#006CD6" // Background color to match your overall theme
    >
      <Box
        width={["100%", "45%"]} // Full width on small screens, 45% on larger screens
        marginRight="-35px"  // Reduced margin to bring panels closer
      >
        <LeftPanel inputConfig={referralInputConfig} />
      </Box>
      <Box
        width={["100%", "45%"]} // Full width on small screens, 45% on larger screens
        marginLeft="-35px"  // Reduced margin to bring panels closer
      >
        <RightPanel />
      </Box>
    </Flex>
  );
};

export default CombinedPanel;
