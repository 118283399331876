import React from 'react';
import { Box, Flex, VStack } from '@chakra-ui/react';
import Header from '../components/Header';
import Banner from '../components/Banner';
import Footer from '../components/Footer';
import FeatureContainer from '../components/FeatureContainer';
import ActionButtons from '../components/ActionButtons';

const registerPageLinks = [
  { href: "#", iconSrc: "/assets/Penguin-icon.png", altText: "Penguin Icon", label: "Home" },
  { href: "#", iconSrc: "/assets/Friends-icon.png", altText: "Friends Icon", label: "Community" },
  { href: "#", iconSrc: "/assets/Badge-icon.png", altText: "Badge Icon", label: "About" },
];

function Homepage() {
  return (
    <Flex
      minH="100vh" // Ensure the page takes up the full viewport height
      flexDirection="column"
      bg="rgb(31,110,186)"
      >
      <Header links={registerPageLinks} />

      {/* The banner and main content */}
      <VStack flex="1" spacing={8} py={8} mt={-8}>
        <Banner />
        <FeatureContainer />
        <ActionButtons />
      </VStack>

      {/* Footer */}
      <Footer />
    </Flex>
  );
}

export default Homepage;
