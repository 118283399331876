import React from 'react';
import { SimpleGrid, Box } from '@chakra-ui/react';
import FeatureBox from './FeatureBox';

const FeatureContainer: React.FC = () => {
  const features = [
    {
      imageSrc: '/assets/activities.png',
      title: 'Activities',
      description:
        'Play mini-games, earn stamps, and so much more on Club PenguiNFT, with new additions constantly being added!',
    },
    {
      imageSrc: '/assets/parties.png',
      title: 'Parties',
      description:
        'Play special events that happen every month, changing the look of the island, giving new tasks, and more exclusive items!',
    },
    {
      imageSrc: '/assets/community.png',
      title: 'Community',
      description:
        'Club PenguiNFT has an active and helpful community, which grows larger every day!',
    },
    {
      imageSrc: '/assets/style.png',
      title: 'Find Your Style',
      description:
        'With new clothing items and catalogs releasing all the time, you can choose what you want to wear!',
    },
  ];

  return (
    <Box maxW="1200px" mx="auto" py={10}>
      <SimpleGrid
        columns={[1, 2, 3, 4]} // Responsive: 1 column on small screens, up to 4 on larger screens
        spacing={6}
        justifyItems="center"
      >
        {features.map((feature, index) => (
          <FeatureBox
            key={index}
            imageSrc={feature.imageSrc}
            title={feature.title}
            description={feature.description}
          />
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default FeatureContainer;
