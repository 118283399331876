import React, { useEffect, useState } from 'react';
import { Button, useBreakpointValue } from '@chakra-ui/react';
import { useEthers } from '@usedapp/core';

const ConnectWalletButton: React.FC = () => {
  const { activateBrowserWallet, account, deactivate } = useEthers();
  const [walletConnected, setWalletConnected] = useState<string | null>(null);
  const buttonSize = useBreakpointValue({ base: "sm", md: "md", lg: "lg" });

  const connectWallet = async () => {
    if (!account) {
      activateBrowserWallet();
    } else {
      deactivate();  // Disconnect current wallet
      activateBrowserWallet();  // Reconnect (which will prompt MetaMask)
    }
  };

  useEffect(() => {
    if (account) {
      setWalletConnected(account);
    }
  }, [account]);

  return (
    <Button
      onClick={connectWallet}
      height={{ base: 10, md: 12, lg: 16 }}
      size={buttonSize}
      color="#684213"
      bgGradient="linear(to-b, #fbf267, #fe951e)"
      boxShadow="0 2px 10px rgba(0, 0, 0, 0.3)"
      borderWidth="1.5px"
      borderColor="#684213"
      fontSize={{ base: 14, md: 18, lg: 23 }}
      _hover={{
        bgGradient: "linear(to-b, #fe951e, #fbf267)",
      }}
    >
      {walletConnected ? `0x...${walletConnected.substring(walletConnected.length - 4)}` : 'Connect Wallet'}
    </Button>
  );
};

export default ConnectWalletButton;
