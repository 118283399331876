import React, { useState } from "react";
import { Box, Text, Link, VStack, Flex } from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";

const TermsOfUse: React.FC = () => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <VStack align="center" spacing={10} width="100%" maxWidth="700px" gap={10}>
      <Flex align="center" width="100%" justifyContent="center">
        {/* Custom Checkbox */}
        <Box
          as="button"
          onClick={handleCheckboxChange}
          width="40px"
          height="40px"
          borderRadius="50%"
          borderWidth="2px"
          borderColor="white"
          bg={isChecked ? "#21d400" : "transparent"}
          display="flex"
          alignItems="center"
          justifyContent="center"
          marginLeft="-45px"
        //   marginRight="2px"
          marginBottom="20px"
        //   marginRight="5px"
          _focus={{ outline: "none" }}
        >
          {isChecked && <CheckIcon w={6} h={6} color="white" />}
        </Box>

        {/* Text Content */}
        <Text
          as="span"
          fontFamily="Burbank Small Medium"
          fontWeight="600"
          fontSize="1.2rem"
          lineHeight="1.75rem"
          color="white"
          ml={4}
          textAlign="center"
        >
          I agree to the{" "}
          <Link href="#" color="yellow.400">
            TERMS OF USE
          </Link>{" "}
          and{" "}
          <Link href="#" color="yellow.400">
            NEW CLUB PENGUIN RULES
          </Link>
          .<br />
          For more information on our Privacy Policy,{" "}
          <Link href="#" color="yellow.400">
            click here
          </Link>
          .
        </Text>
      </Flex>
      <Box width="80%" borderBottom="3px solid" borderColor="gray.400" />
    </VStack>
  );
};

export default TermsOfUse;
