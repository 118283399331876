import React, { useState } from "react";
import { VStack, Button, Text, Spacer } from "@chakra-ui/react";
import TermsOfUse from "./TermsOfUse";
import Captcha from "./Captcha";
import ReusableInput from "./ReusableInput";

const RightPanel: React.FC = () => {
  // State for the username input
  const [username, setUsername] = useState<string>("");
  const [usernameValidation, setUsernameValidation] = useState<
    "success" | "error" | "neutral"
  >("neutral");
  const [usernameErrorMessage, setUsernameErrorMessage] = useState<
    string | undefined
  >();

  const validateUsername = (value: string) => {
    if (value.length < 3) {
      setUsernameValidation("error");
      setUsernameErrorMessage("That penguin name is too short.");
    } else if (value === "ponky") {
      setUsernameValidation("error");
      setUsernameErrorMessage("That penguin name is already taken.");
    } else {
      setUsernameValidation("success");
      setUsernameErrorMessage(undefined);
    }
  };

  const usernameInputConfig = {
    label: "Create a Penguin Name",
    placeholder: "Enter your penguin name",
    value: username,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
      setUsername(e.target.value);
      validateUsername(e.target.value);
    },
    validationStatus: usernameValidation,
    validationMessage: usernameErrorMessage,
    inputType: "text",
  };

  // State for the email input
  const [email, setEmail] = useState<string>("");
  const [emailValidation, setEmailValidation] = useState<
    "success" | "error" | "neutral"
  >("neutral");
  const [emailErrorMessage, setEmailErrorMessage] = useState<
    string | undefined
  >();

  const validateEmail = (value: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!value) {
      setEmailValidation("neutral");
      setEmailErrorMessage(undefined);
    } else if (!emailRegex.test(value)) {
      setEmailValidation("error");
      setEmailErrorMessage("Please enter a valid email address.");
    } else {
      setEmailValidation("success");
      setEmailErrorMessage(undefined);
    }
  };

  const emailInputConfig = {
    label: "Enter Your Email",
    placeholder: "Enter your email",
    value: email,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
      setEmail(e.target.value);
      validateEmail(e.target.value);
    },
    validationStatus: emailValidation,
    validationMessage: emailErrorMessage,
    inputType: "email",
  };

  // State for selected penguin color
  const [selectedColor, setSelectedColor] = useState<string | null>(null);

  // State for agreeing to the terms of use
  const [agreedToTerms, setAgreedToTerms] = useState<boolean>(false);

  // Handle terms of use agreement
  const handleTermsOfUseChange = (checked: boolean) => {
    setAgreedToTerms(checked);
  };

  // Determine if the button should be clickable
  const isButtonEnabled =
    usernameValidation === "success" && selectedColor !== null && agreedToTerms;

  // Handle button click event
  const handleCreatePenguin = () => {
    if (isButtonEnabled) {
      console.log("Penguin created with:", {
        username,
        email,
        color: selectedColor,
      });
      // Here you would call your backend API to create the account
    }
  };

  return (
    <VStack align="center" spacing={10} width="100%">
      <ReusableInput inputConfig={usernameInputConfig} />
      <ReusableInput inputConfig={emailInputConfig} />
      <TermsOfUse />
      <Captcha />
      <Spacer />
      <Button
        marginTop={4}
        width="100%"
        maxWidth="500px"
        height="60px"
        fontSize="1.5rem"
        fontFamily="Burbank Small"
        fontWeight="bold"
        borderRadius="12px"
        color="white"
        background={
          isButtonEnabled
            ? "linear-gradient(-180deg, #6ed200, #5db100)"
            : "linear-gradient(-180deg, rgba(110, 210, 0, 0.8), rgba(93, 177, 0, 0.8))"
        }
        boxShadow={
          isButtonEnabled
            ? "0 3px 0 0 rgba(0, 62, 113, .3), inset 0 1px 1px 0 #ccff7d"
            : "0 3px 0 0 rgba(0, 62, 113, .3)"
        }
        border="1px solid rgba(0, 63, 114, .8)"
        transition="all .2s ease 0s"
        _hover={
          isButtonEnabled
            ? { cursor: "pointer" }
            : {
                background:
                  "linear-gradient(-180deg, rgba(110, 210, 0, 0.6), rgba(93, 177, 0, 0.6))",
              }
        }
        _active={
          isButtonEnabled
            ? {
                boxShadow:
                  "0 3px 0 0 rgba(0, 62, 113, .3), inset 0 1px 1px 0 #ccff7d",
              }
            : undefined
        }
        onClick={handleCreatePenguin}
        isDisabled={!isButtonEnabled}
      >
        CREATE YOUR PENGUIN
      </Button>
    </VStack>
  );
};

export default RightPanel;
