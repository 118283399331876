import React, {useState} from "react";
import { Box, Text, VStack } from "@chakra-ui/react";
import PenguinColorSelector from "./PenguinColorSelector";
import ReusableInput from "./ReusableInput";
import ReusableInputProps from "../interfaces/ReusableInput.interface";

const LeftPanel: React.FC<ReusableInputProps> = ({ inputConfig }) => {

    return (
    <VStack spacing={10} align="center" width="100%">
      {/* Penguin Color Selector */}
      <Box width="100%" maxWidth="500px">
        <PenguinColorSelector />
      </Box>

      {/* Additional space between the components */}
      <Box height="50px" />

      {/* Referral Code Input */}
      <Box width="100%" maxWidth="450px">
        <ReusableInput inputConfig={inputConfig} />

        {/* Description Text */}
        <Text
          color="white"
          fontSize="1.2rem"
          fontFamily="Burbank Small Medium"
          fontWeight="600"
          textAlign="center"
          mt={6}
        >
          If applicable, entering a referral code will give you, and the penguin
          the code belongs to '500 coins'.
        </Text>
      </Box>
    </VStack>
  );
};

export default LeftPanel;
