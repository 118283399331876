import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Register from "./pages/Register"; // Example of a Home page component
import Homepage from "./pages/Homepage";
const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        {/* Define your routes here */}
        <Route path="/" element={<Homepage />} />
        <Route path="/register" element={<Register />} />
        {/* <Route path="*" element={<NotFoundPage />} />  Catch all unmatched routes */}
      </Routes>
    </Router>
  );
};

export default App;
