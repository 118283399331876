import React from 'react';
import { Box, Image, Text, VStack } from '@chakra-ui/react';

interface FeatureBoxProps {
  imageSrc: string;
  title: string;
  description: string;
}

const FeatureBox: React.FC<FeatureBoxProps> = ({ imageSrc, title, description }) => {
  return (
    <Box
      bg="rgb(10, 148, 240)" // Blue background color
      borderRadius="20px"
      padding="20px"
      maxW="250px" // Reduced max width
      textAlign="center"
      boxShadow="lg"
      _hover={{ transform: 'scale(1.05)', transition: 'all 0.3s ease' }} // Hover effect
    >
      <VStack spacing={3}>
        <Image 
          src={imageSrc} 
          alt={title} 
          width="100%" 
          objectFit="cover"
          borderRadius="10px"
        />
        <Text fontWeight="bold" fontSize="1.3rem" color="white">
          {title}
        </Text>
        <Text fontSize="0.9rem" color="white">
          {description}
        </Text>
      </VStack>
    </Box>
  );
};

export default FeatureBox;
