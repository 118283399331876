import React, { useState, useEffect } from "react";
import { Box, Text, Image, VStack, Button } from "@chakra-ui/react";

const options = [
  { label: "Cheese", image: "/assets/Cheese-Captcha.png" },
  { label: "Watermelon", image: "/assets/Watermelon-Captcha.png" },
  { label: "Popcorn", image: "/assets/Popcorn-Captcha.png" },
];

const Captcha: React.FC = () => {
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [targetOption, setTargetOption] = useState<string | null>(null);
  const [isCorrectSelected, setIsCorrectSelected] = useState<boolean>(false);
  const [clickedWrong, setClickedWrong] = useState<boolean>(false);

  useEffect(() => {
    const randomOption = options[Math.floor(Math.random() * options.length)];
    setTargetOption(randomOption.label);
  }, []);

  const handleOptionClick = (label: string) => {
    if (!isCorrectSelected) {
      setSelectedOption(label);
      if (label === targetOption) {
        setIsCorrectSelected(true);
      } else {
        setClickedWrong(true);
        setTimeout(() => setClickedWrong(false), 300); // Reset the wrong click effect after 0.3 seconds
      }
    }
  };

  return (
    <VStack align="center" spacing={4} width="100%">
      <Text
        fontFamily="Burbank Small"
        fontSize="1.9rem"
        color="white"
        fontWeight="bold"
      >
        PICK THE{" "}
        <Text as="span" color="yellow.400">
          {targetOption?.toUpperCase()}
        </Text>
      </Text>

      <Box display="flex" justifyContent="center" alignItems="center" gap={10} marginTop={16}>
        {options.map((option, index) => (
          <Button
            key={index}
            onClick={() => handleOptionClick(option.label)}
            background="none"
            p={0}
            _hover={{}} // Completely disable hover effects
            _active={{}} // Disable active effects
            _focus={{ outline: "none" }} // Disable focus outline
            transform={
              selectedOption === option.label && selectedOption === targetOption
                ? "scale(1.2)"
                : clickedWrong && selectedOption === option.label
                ? "scale(0.9)"
                : isCorrectSelected
                ? "scale(0.85)" // Further reduce size of others when correct is selected
                : "none"
            }
            transition={
              clickedWrong && selectedOption === option.label
                ? "transform 0.3s ease-in-out"
                : "transform 0.15s ease-in-out"
            }
          >
            <Box
              bg="#0152AF" // Darker blue
              borderRadius="full"
              boxSize="110px" // Larger size
              display="flex"
              alignItems="center"
              justifyContent="center"
              border={
                selectedOption === option.label &&
                selectedOption === targetOption
                  ? "4px solid #21D400"
                  : "none"
              }
              opacity={
                isCorrectSelected && selectedOption !== option.label ? 0.5 : 1
              }
            >
              <Image
                src={option.image}
                alt={option.label}
                boxSize="70px"
                transition="opacity 0.15s ease-in-out"
              />
            </Box>
          </Button>
        ))}
      </Box>
    </VStack>
  );
};

export default Captcha;
