import React from 'react';
import { Button, Flex, Link } from '@chakra-ui/react';

const ActionButton: React.FC<{ text: string; href: string }> = ({ text, href }) => (
  <Link href={href} _hover={{ textDecoration: 'none' }}>
    <Button
      as="span"
      height={16}
      width="240px"
      size="lg"
      color="#684213"
      bgGradient="linear(to-b, #fbf267, #fe951e)"
      boxShadow="0 2px 10px rgba(0, 0, 0, 0.3)"
      borderWidth="1.5px"
      borderColor="#684213"
      fontSize={23}
      _hover={{
        bgGradient: "linear(to-b, #fe951e, #fbf267)",
      }}
    >
      {text}
    </Button>
  </Link>
);

const ActionButtons: React.FC = () => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      gap={14}
      flexWrap="wrap"
      maxW="600px"
      mx="auto"
      my={8}
      mt={0}
    >
      <ActionButton text="Play Now" href="/play" />
      <ActionButton text="Create Penguin" href="/register" />
    </Flex>
  );
};

export default ActionButtons;