// Header.tsx
import React from 'react';
import { Flex, Heading, Spacer, Box, useBreakpointValue } from '@chakra-ui/react';
import ConnectWalletButton from './ConnectWalletButton';
import NavLink from './NavLink';
import MobileMenu from './MobileMenu';

interface HeaderProps {
  links: Array<{
    href: string;
    iconSrc: string;
    altText: string;
    label: string;
  }>;
}

const Header: React.FC<HeaderProps> = ({ links }) => {
  const breakpoint = useBreakpointValue({ base: 'mobile', md: 'tablet', lg: 'desktop' });

  return (
    <Flex
      as="header"
      bg="linear-gradient(180deg, #12a8fe, #016cd7)"
      px={{ base: 4, md: 6, lg: 10 }}
      py={5}
      alignItems="center"
      shadow="md"
      zIndex={10}
      position="fixed"
      width="100%"
      top={0}
      boxShadow="0 2px 10px rgba(0, 0, 0, 0.3)"
      height="90px"
    >
      <Heading as="h1" size="sm" color="white" flexShrink={0}>
        <img 
          src="/assets/logo.png" 
          alt="Club PenguiNFT" 
          width={breakpoint === 'mobile' ? "120" : breakpoint === 'tablet' ? "150" : "190"} 
          style={{marginRight: breakpoint === 'mobile' ? "10px" : breakpoint === 'tablet' ? "20px" : "40px"}}
        />
      </Heading>

      <Spacer />

      {breakpoint === 'mobile' ? (
        <MobileMenu links={links} />
      ) : (
        <Flex 
          gap={{ md: 4, lg: 8, xl: 32 }} 
          alignItems="center" 
          flexWrap="wrap" 
          justifyContent="center"
        >
          {links.map((link, index) => (
            <NavLink
              key={index}
              href={link.href}
              iconSrc={link.iconSrc}
              altText={link.altText}
              label={link.label}
            />
          ))}
        </Flex>
      )}

      <Spacer />

      {breakpoint !== 'mobile' && (
        <Box flexShrink={0}>
          <ConnectWalletButton />
        </Box>
      )}
    </Flex>
  );
};

export default Header;
