// theme.ts
import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  fonts: {
    heading: `"Burbank Small", sans-serif`, // For headings
    body: `"Burbank Small", sans-serif`, // For body text
  },
});

export default theme;
