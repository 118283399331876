import React from 'react';
import { Link, Image } from '@chakra-ui/react';

interface NavLinkProps {
  href: string;
  iconSrc: string;
  altText: string;
  label: string;
}

const NavLink: React.FC<NavLinkProps> = ({ href, iconSrc, altText, label }) => {
  return (
    <Link
      href={href}
      color="white"
      fontSize="2xl"
      display="flex"
      alignItems="center"
      textDecoration="none"
      _hover={{
        color: '#ffc107',
        textDecoration: 'underline',
        textUnderlineOffset: '12px',
        transition: 'color 0.3s, text-decoration-color 0.3s',
      }}
    >
      <Image src={iconSrc} alt={altText} paddingBottom={4} mr={3} />
      {label}
    </Link>
  );
};

export default NavLink;
