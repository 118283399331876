import React from "react";
import {
  Box,
  Input,
  FormControl,
  FormLabel,
  InputGroup,
  InputRightElement,
  Icon,
} from "@chakra-ui/react";
import { CheckCircleIcon, WarningIcon } from "@chakra-ui/icons";
import ReusableInputProps from "../interfaces/ReusableInput.interface";

const ReusableInput: React.FC<ReusableInputProps> = ({ inputConfig }) => {
  const {
    label,
    placeholder,
    value,
    onChange,
    validationStatus,
    inputType,
    validationMessage,
  } = inputConfig;

  const getIcon = () => {
    if (validationStatus === "success")
      return <CheckCircleIcon color="green.500" width="30px" height="30px" marginRight={4}/>;
    if (validationStatus === "error") return <WarningIcon color="red.500" width="30px" height="30px" marginRight={4} />;
    return null;
  };

  return (
    <Box width="500px">
    <FormControl isInvalid={validationStatus === "error"}>
      <FormLabel
        color="white"
        fontFamily="Burbank Small"
        fontSize="2.2rem"
        mb={3}
      >
        {label}
      </FormLabel>
      <InputGroup>
        <Input
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          type={inputType}
          borderColor={validationStatus === "error" ? "red.500" : "blue.500"}
          focusBorderColor={
            validationStatus === "error" ? "red.500" : "blue.500"
          }
          borderWidth="2px"
          borderRadius="10px"
          fontFamily="sans-serif"
          fontWeight="700"
          fontSize="1.6rem"
          height="4rem"
          padding="0.5rem 1rem"
          backgroundColor="white"
          color="black"
          textAlign="center"
          _placeholder={{
            color: "#0555BC", // Change this to the desired color
            fontSize: "1.6rem", // You can also adjust the size of the placeholder if needed
            fontWeight: "600"
          }}
        
        //   width="100%"
        />
        {validationStatus === "error" && (
          <>
            <InputRightElement pointerEvents="none" height="100%" top="0">
              {getIcon()}
            </InputRightElement>
            <Box
              position="absolute"
              top="auto"
              left="50%"
              height="2.5rem"
              width="70%"
              transform="translateX(-50%) translateY(calc(-100% - 10px))"
              bg="yellow.400"
              color="black"
              p={1.5}
              rounded="md"
              shadow="md"
              fontSize="1rem"
              fontFamily="sans-serif"
              fontWeight={600}
              zIndex="10"
              textAlign="center"
              _after={{
                content: `""`,
                position: "absolute",
                top: "95%",
                left: "50%",
                transform: "translateX(-50%)",
                borderLeft: "10px solid #00000000",
                borderRight: "10px solid #00000000",
                borderTop: "10px solid #EBC94A",
                borderBottom: "10px solid #00000000",
              }}
            >
              {validationMessage}
            </Box>
          </>
        )}
        {validationStatus === "success" && (
          <InputRightElement pointerEvents="none" height="100%" top="0">
            {getIcon()}
          </InputRightElement>
        )}
      </InputGroup>
    </FormControl>
    </Box>
  );
};

export default ReusableInput;
