import React, { useEffect } from 'react';

const Banner: React.FC = () => {
  useEffect(() => {
    // Ensure RufflePlayer is loaded
    if (!document.getElementById('ruffle-script')) {
      const ruffleScript = document.createElement('script');
      ruffleScript.id = 'ruffle-script';
      ruffleScript.src = 'https://cdn.jsdelivr.net/npm/@ruffle-rs/ruffle@0.1.0-nightly.2023.6.22/ruffle.min.js';
      ruffleScript.async = true;
      document.body.appendChild(ruffleScript);

      ruffleScript.onload = () => {
        if (window.RufflePlayer) {
          const ruffleInstance = window.RufflePlayer.newest();
          const playerElement = ruffleInstance.createPlayer();
          const container = document.getElementById('ruffle-container');

          if (container && !container.firstChild) {
            container.appendChild(playerElement);

            // Style the player to cover the full width and scale properly
            playerElement.style.width = '100%';
            playerElement.style.height = 'auto';
            playerElement.style.display = 'flex';
            playerElement.style.justifyContent = 'center';
            playerElement.style.alignItems = 'center';
            playerElement.style.position = 'relative';
            playerElement.style.flexDirection = 'row';
            playerElement.style.minHeight = '500px';
            playerElement.style.marginBottom = '30px';
            playerElement.style.top = '90px';

            // Load the SWF file
            (playerElement as any).load({
              url: '/banner.swf',
              autoplay: "on",
              preloader: false,
              unmuteOverlay: "hidden",
              contextMenu: false,
              // forceAlign: true,
              // letterBox: 'on',
              // forceScale: true,
            });
          }
        }
      };
    }
  }, []);

  return (
      <div id="ruffle-container" style={{ width: '100%', height: '100%' }}></div>
  );
};

export default Banner;
