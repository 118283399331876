import React from 'react';
import { Box } from '@chakra-ui/react';
import Header from '../components/Header';
import CombinedPanel from '../components/CombinedPanel';


const registerPageLinks = [
  { href: "#", iconSrc: "/assets/Penguin-icon.png", altText: "Penguin Icon", label: "Home" },
  { href: "#", iconSrc: "/assets/Friends-icon.png", altText: "Friends Icon", label: "Community" },
  { href: "#", iconSrc: "/assets/Badge-icon.png", altText: "Badge Icon", label: "Help" },
];

function Register() {
  return (
    <Box
      minH="100vh"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      bg="rgb(0, 122, 215)"
      pt="60px" // Add padding top to avoid overlap with header
    >
      <Header links={registerPageLinks} />
      {/* <SignUpForm /> */}
      {/* <PenguinColorSelector /> */}
      {/* <ExamplePage /> */}
      {/* <LeftPanel inputConfig={referralInputConfig} /> */}
      {/* <PenguinForms inputConfig={referralInputConfig} /> */}
      {/* <TermsOfUse /> */}
      {/* <Captcha /> */}
      {/* <RightPanel /> */}
      <CombinedPanel />
    </Box>
  );
}

export default Register;
